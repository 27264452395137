import QwidButton from 'components/misc/QwidButton';
import {
  clearInternalTransferDetails,
  closeNewRecipientState,
  setInternalCurrentPage,
} from 'redux/slices/transactionsSlice';
import { DASHBOARD_ROUTES } from 'utils/appData/appRoutes';
import useToolkit from 'utils/hooks/misc/useToolkit';
import Layout from './internal-transfer/Layout';
import { RemittanceContext } from './remittance/context';
import { useContext } from 'react';
import { RemittanceDirective } from 'custom-types';
import { useAppSelector } from 'redux/store';
import { SuccessVector } from './common/local-components/SuccessVector';
import Image from 'next/image';

interface SuccessPageProps {
  amount?: string;
  name?: string;
  transId?: string;
  doneFxn?: () => void;
  isForConvert?: boolean;
  conversionAmount?: string;
  justDoneBtn?: boolean;
  forInternal?: boolean
}
const SuccessPage = ({
  amount,
  name,
  transId,
  doneFxn,
  isForConvert,
  conversionAmount,
  justDoneBtn = false,
  forInternal = false
}: SuccessPageProps) => {
  const { remittanceDirective } = useContext(RemittanceContext);
  const {
    withdrawalInfo: { isCreatingNewRecipient, isNewAccountWithdrawal },
  } = useAppSelector((state: any) => state.sendflow);
  const { router, dispatch } = useToolkit();
  const handleDone = () => {
    if (doneFxn) doneFxn();
  };
  const sendAgain = () => {
    setTimeout(() => {
      dispatch(clearInternalTransferDetails());
      dispatch(setInternalCurrentPage(1));
      dispatch(closeNewRecipientState());
    }, 200);
    router.push(DASHBOARD_ROUTES.SEND);
  };
  const checkName = (name?: string) => {
    if(name){
      if (name === 'N/A') {
        return ''
      }
      return `to ${name}`;
    }
    else return ''
  };
  return (
    <Layout>
      <div className="m-auto  relative flex flex-col text-white bg-[#044A62]  max-w-[35rem] min-h-[400px]   rounded-lg items-center">
        <div className="absolute z-[1] object-cover opacity-[2%] opacity rounded-[2rem] top-0 w-full right-0">
          <Image
            className="rounded-[1rem]"
            objectFit="cover"
            width={560}
            height={400}
            alt="success"
            src={'/assets/dashboard/SuccessBgVector.svg'}
          />
        </div>
        <div className="m-auto z-[4] flex w-full flex-col gap-4 items-center max-w-[26rem]">
          <SuccessVector />
          <h2 className="text-[1.02rem] text-white font-semibold">
            Congratulations
            {/* {(remittanceDirective === RemittanceDirective.ADD_RECIPIENT || (isCreatingNewRecipient && !isNewAccountWithdrawal))
              ? 'Beneficiary Added'
              : 'Transaction initiat'} */}
          </h2>
          <p className="text-center mt-[-0.7rem] text-[0.85rem]">
            {isForConvert
              ? `Your conversion of ${conversionAmount}  to ${amount} was successful`
              : (remittanceDirective === RemittanceDirective.ADD_RECIPIENT ||
                (isCreatingNewRecipient && !isNewAccountWithdrawal)) && !forInternal
              ? 'We have successfully added a new account as your beneficiary'
              : `Your transfer of ${amount} ${checkName(name)} was successful, you can view details in Transaction history`}
          </p>
          {/* {(remittanceDirective !== RemittanceDirective.ADD_RECIPIENT && !isCreatingNewRecipient) && (
            <>
              <div>Amount</div>
              <p className='text-[1.4rem] font-semibold'>{amount}</p>
              <div className='flex w-full p-4 flex-col gap-3 mb-[1rem] border-[1px] rounded-[1rem]'>
                {name && (
                  <div className='flex w-full items-start justify-between pb-[0.6rem] border-b-[1px] border-[#F3F3F3]'>
                    <h1 className='font-semibold'>Recipient's name</h1>
                    <div className='text-[0.9rem]'>{name}</div>
                  </div>
                )}
                <div className='flex w-full justify-between'>
                  <h1 className='text-[#62666E]'>Transaction ID</h1>
                  <div>{transId}</div>
                </div>
              </div>
            </>
          )} */}
          <div className="w-full flex gap-2 max-w-[16rem]">
            {!justDoneBtn && !isForConvert && (remittanceDirective !== RemittanceDirective.ADD_RECIPIENT ||
                !(isCreatingNewRecipient && !isNewAccountWithdrawal))  && (
                <QwidButton
                  isFull
                  text={'Send money again'}
                  onClick={sendAgain}
                  customBg="#215E74"
                />
              )}
            <div className="min-w-[34%] w-full">
              <QwidButton
                customBg="#077EA7"
                isFull
                text={'Done'}
                onClick={handleDone}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SuccessPage;

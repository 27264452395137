import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useFramer, useToolkit } from "../../components";
import { Modal } from "antd";

type CenterModalProps = {
	children: React.ReactNode;
	action?: React.ReactNode;
	title?: string | JSX.Element;
	okText?: string;
	className?: string;
	isCustom?: boolean;
	onClose?: () => void;
	onModalSubmit?: () => void;
	control: boolean;
	isRounded?: boolean;
	isMobileMenu?: boolean;
	isAntd?: boolean;
	darkBg?: boolean;
	width?: number;
};

const CenterModal = ({
	children,
	title,
	isCustom,
	onClose,
	control,
	isRounded,
	isMobileMenu,
	darkBg,
	action,
	isAntd,
	okText,
	onModalSubmit,
	className,
	width,
}: CenterModalProps) => {
	const { modalWrapperVars, modalChildVars } = useFramer();
	const { isMobile } = useToolkit();

	return isAntd ? (
		<Modal
			width={width || ""}
			destroyOnClose
			footer={null}
			title={title}
			centered
			visible={control}
			onOk={onModalSubmit}
			onCancel={onClose}
			okText={<span className="px-2">{okText || "Add"}</span>}
			cancelText="Cancel"
			closeIcon={<span></span>}>
			{children}
		</Modal>
	) : control ? (
		<section
			onClick={onClose}
			style={{ zIndex: "900" }}
			className={`fixed top-0 left-0 right-0 bottom-0 backdrop-blur-[2px] flexed min-w-full z-50 ${
				darkBg ? "bg-[#00000066]" : "bg-[#B7B7B769] "
			} ${
				isMobileMenu ? "p-0 overflow-y-hidden" : "p-4 lg:p-0 overflow-y-scroll"
			}`}>
			{isCustom ? (
				<motion.div
					variants={modalWrapperVars}
					initial="initial"
					animate="animate"
					className={`bg-white z-50  mx-auto rounded-lg ${className}`}
					onClick={(e) => e.stopPropagation()}>
					{children}
				</motion.div>
			) : isMobileMenu ? (
				<AnimatePresence>
					<motion.div
						variants={modalWrapperVars}
						initial="initial"
						animate="animate"
						onClick={(e) => e.stopPropagation()}
						className={`bg-white h-full w-full rounded-sm shadow-md z-50 ${
							isRounded ? "rounded-2xl" : ""
						}`}>
						<motion.div
							variants={modalChildVars}
							initial="initial"
							animate="animate"
							exit={{ right: "-100vw" }}
							className="px-6 py-3">
							{children}
						</motion.div>
					</motion.div>
				</AnimatePresence>
			) : (
				<motion.div
					variants={modalWrapperVars}
					initial="initial"
					animate="animate"
					onClick={(e) => e.stopPropagation()}
					className={`bg-white min-h-[220px] w-full md:w-[55%] lg:w-[30%]  shadow-md z-50 ${
						isRounded ? "rounded-3xl" : "rounded-md"
					}`}>
					<motion.div
						variants={modalChildVars}
						initial="initial"
						animate="animate"
						className="p-6">
						<div className="flex-btw mb-5">
							{title && (
								<p className="font-semibold mb-0 tracking-normal">{title}</p>
							)}
							{action && <div>{action}</div>}
						</div>
						{children}
					</motion.div>
				</motion.div>
			)}
		</section>
	) : null;
};

export default CenterModal;
